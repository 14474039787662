.rules__list--wrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 8px;
}

.rules__wrapper {
    padding: 16px;
}

.rule__list--point {
    margin: 16px;
    display: flex;
    font-size: 20px;
}

.list__icon {
    color: #1F51FF;
    margin-right: 10px;
    font-size: 24px;
}

.sub--icon {
    color: var(--primary);
    font-size: 18px;
}

.sub--list {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin: 0 40px;
}

.rules__para {
    margin: 10px 0;
    font-weight: 600;
    font-size: 20px;
}

.section__title {
    font-size: 24px;
    border-image: linear-gradient(120deg, var(--primary), #1e90ff);
    border-image-slice: 1;
    border-style: solid;
    border-width: 5px 0 0;
    border-bottom: 5px solid;
    border-top: 0;
    padding-bottom: 10px;
    width: fit-content;
    text-align: left;
}

.rule__list--point-sub {
    align-items: center;
}

@media(max-width:560px) {

    .rule__list--point {
        font-size: 14px;
        margin: 8px 0;
    }

    .section__title {
        font-size: 20px;
    }

    .list__icon {
        font-size: 18px;
        margin: 4px;
    }

    .sub--icon {
        color: var(--primary);
        font-size: 16px;
    }

    .rules__para {
        font-size: 16px;
    }
}