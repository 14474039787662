.app {
  margin-top: 32px;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  margin-top: 32px;
  background-color: #111;
  padding: 32px;
  border-radius: 32px;
  box-shadow: 0px 16px 16px 0px rgb(117, 117, 117);
}

.announcement__container--skeleton {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.announcement__wrapper-skeleton {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}

.announcement__title--skeleton {
  height: 24px;
  width: 160px;
  background-color: gray;
}

.announcement__para--skeleton {
  height: 16px;
  width: 80px;
  background-color: gray;
}
.announcement__button--skeleton {
  height: 16px;
  width: 88px;
  background-color: gray;
}

.announcement__title--skeleton,
.announcement__para--skeleton,
.announcement__button--skeleton {
  text-align: center;
  color: gray;
  margin: 8px 0px;
}

.announcement__container {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.announcement__wrapper {
  display: flex;
  color: white;
  text-align: center;
  align-items: center;
  flex-direction: column;
  gap: 16px;
  margin: 32px 0px;
  max-width: 500px;
}

.announcement__input--wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  border-bottom: 1px solid rgba(255, 254, 254, 0.571);
  padding: 16px 0px;
}

.announcement__delete {
  padding: 0px 16px;
}

.announcement__title {
  word-break: break-word;
}

.announcement__description {
  word-break: break-word;
}

.announcement__input--title {
  width: 100%;
  max-width: 310px;
  height: 56px;
  padding: 0px 16px;
  border-radius: 48px;
}

.announcement__input--description {
  width: 100%;
  max-width: 300px;
  height: 150px;
  padding: 8px;
  border-radius: 8px;
}

.logout__btn,
.create__announcement,
.announcement__delete {
  background-color: #1f51ff;
  border: none;
  border-radius: 8px;
  height: 40px;
  width: 100%;
  max-width: 200px;
  cursor: pointer;
  color: white;
  font-weight: bold;
  font-size: 16px;
  transition: all 300ms ease;
}
.logout__btn:hover,
.create__announcement:hover,
.announcement__delete:hover {
  background-color: #44d62c;
}
