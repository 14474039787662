.dep__card--icon {
  color: #1f51ff;
  height: 40px;
  margin-top: 10px;
}

.dep__card {
  background-color: #1c1b25;
  display: flex;
  flex-direction: column;
  justify-content: start;
  width: 30%;
  gap: 8px;
  list-style: circle;
  height: 455px;
  transition: 0.5s;
}

.dep__card--title {
  font-size: 16px;
}

.main__title--dep {
  font-size: 20px;
  margin: 0 auto;
  padding-bottom: 10px;
  text-align: center;
  height: 100px !important;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.dep__card--title {
  color: var(--primary);
  background: linear-gradient(120deg, var(--primary), #a6c316);

  height: fit-content;
  font-weight: 700;
  background-clip: text;
  color: transparent;
}

.dep__card:hover {
  transform: translateY(-5px) scale(1.005) translateZ(0);
  box-shadow: 0 18px 16px #1e90ff, 0 18px 16px #1e90ff;
}

.dep__card::after,
::before {
  box-sizing: content-box !important;
}

.card__circle {
  margin-right: 4px;
}

.description__para {
  font-size: 16px;
  color: white;
}

@media (max-width: 900px) {
  .dep__card {
    width: 90%;
    justify-content: center;
  }
}
