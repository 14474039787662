.ranks__wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    padding: 48px 0;
}

@media(max-width:960px) {
    .ranks__wrapper {
        justify-content: center;
    }

}

.ranks__wrapper--title {
    border-image: linear-gradient(120deg, var(--primary), var(--primary));
    border-image-slice: 1;
    border-style: solid;
    border-width: 5px 0 0;
    border-bottom: 5px solid;
    border-top: 0;
    margin: 0 auto;
    padding-bottom: 20px;
    width: fit-content !important;
}