.login__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 32px;
    margin-top: 32px;
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
    margin-top: 32px;
    background-color: #111;
    padding: 32px;
    border-radius: 32px;
    box-shadow: 0px 16px 16px 0px rgb(117 117 117);
  }
  
  .login__title {
    color: white;
    text-align: center;
  }
  
  .input__email, .input__password{
    width: 100%;
    max-width: 300px;
    height: 56px;
    padding: 0px 16px;
    border-radius: 48px;
  }

  .admin__login--btn{
    background-color: #1f51ff;
    border: none;
    border-radius: 8px;
    height: 40px;
    width: 100%;
    max-width: 200px;
    cursor: pointer;
    color: white;
    font-weight: bold;
    font-size: 16px;
    transition: all 300ms ease;
  }

  .admin__login--btn:hover{
    background-color: #44d62c;
  }