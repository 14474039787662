.logo__header {
  height: 200px;
  width: 225px;
}

header {
  position: relative;
  display: flex;
  justify-content: center;
  margin: 0 auto;
  align-items: center;
  height: calc(100vh - 105px);
}

.header__para {
  font-size: 42px;
  line-height: 2;
  max-width: 600px;
  width: 100%;
}

.social__link {
  font-size: 50px;
  background-color: var(--primary);
  color: white;
  padding: 6px;
  height: 80px;
  width: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.social__list {
  margin-top: 16px;
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: center;
}

.header__content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 100;
  padding: 36px;
  text-align: center;
}

.click {
  transition: all 300ms ease;
}

.click:hover {
  transform: scale(1.1);
}

.click:active {
  transform: scale(0.8);
}

#lineh1 {
  position: absolute;
  left: 0;
  height: 10px;
  background: var(--primary);
  animation: lineH 4s 5s infinite linear;
}

#lineh2 {
  position: absolute;
  left: 0;
  top: 100px;
  height: 10px;
  background: #1f51ff;
  animation: lineH 5s 0.5s infinite linear;
}

#lineh3 {
  position: absolute;
  left: 0;
  top: 200px;
  height: 10px;
  background: var(--primary);
  animation: lineH 5s 2s infinite linear;
}

#lineh4 {
  position: absolute;
  right: 0;
  top: 300px;
  height: 10px;
  background: #1f51ff;
  animation: lineH 5s 1s infinite linear;
}

#lineh5 {
  position: absolute;
  left: 0;
  top: 400px;
  height: 10px;
  background: var(--primary);
  animation: lineH 5s 4s infinite linear;
}

#lineh6 {
  position: absolute;
  right: 0;
  top: 500px;
  height: 10px;
  background: #1f51ff;
  animation: lineH 4s 1s infinite linear;
}

#lineh7 {
  position: absolute;
  right: 0;
  top: 600px;
  height: 10px;
  background: var(--primary);
  animation: lineH 4s 0s infinite linear;
}

#lineh8 {
  position: absolute;
  left: 0;
  top: 700px;
  height: 10px;
  width: 10%;
  background: #1f51ff;
  animation: lineH 4s 1.5s infinite linear;
}

#lineh9 {
  position: absolute;
  left: 0;
  top: 800px;
  height: 10px;
  background: var(--primary);
  animation: lineH 5s 2s infinite linear;
}

#lineh10 {
  position: absolute;
  right: 0;
  top: 900px;
  height: 10px;
  background: #1f51ff;
  animation: lineH 4s 3s infinite linear;
}

@keyframes lineH {
  0% {
    width: 0%;
  }

  100% {
    width: 95%;
    opacity: 0;
  }
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box !important;
}

.twitter__container {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 0px 25px 25px 25px;
  z-index: +1;
}

@media (max-width: 362px) {
  .twitter__container {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 100px 25px 25px 25px;
    z-index: +1;
  }
}
