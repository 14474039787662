body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #111;
  color: white;
  overflow-x: hidden !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --bodoni: "Bodoni Moda";
  --h1: 67.34px;
  --h2: 50.52px;
  --h3: 37.9px;
  --h4: 28.43px;
  --h5: 21.33px;
  --p1: 16px;
  --p2: 12px;
  --small: 6.76px;
  --primary: #44d62c;
  --click: click 0.5s;
}

nav {
  height: 70px;
  background-color: #111;
  display: flex;
  z-index: 25;
}

img {
  width: 100%;
}

p {
  line-height: 1.5;
}

a {
  text-decoration: none;
}

ul {
  display: flex;
}

li {
  list-style-type: none;
}

h1 {
  font-size: 56px;
  margin-bottom: 24px;
  text-align: center;
}

h2 {
  font-size: 32px;
  margin-bottom: 32px;
  text-align: center;
}

button {
  cursor: pointer;
}

button:active {
  transform: translateY(1px);
}

.container {
  padding: 56px 0;
}

.row {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

.title {
  font-size: 60px;
  margin-bottom: 12px;
  text-align: center;
  line-height: 1;
}

.pages__title {
  text-align: center;
  font-size: 48px;
  margin-bottom: 60px;
}

@media (max-width: 768px) {
  .title {
    font-size: 80px;
  }
}

@media (max-width: 480px) {
  .title {
    font-size: 52px;
  }

  .pages__title {
    font-size: 32px;
  }
}
