.nav__container {
    width: 100%;
    max-width: 1200px;
    padding: 0 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
}
.nav__container--home {
    justify-content: center;
}
.nav__links {
    display: flex;
    justify-content: space-between;
}

.nav__link {
    margin-left: 16px;
    text-decoration: none;
    font-size: 20px;
    color: var(--primary);
    font-weight: bold;
    padding: 8px;
}

.nav__link--primary {
    border: 2px solid #44D62C;
    border-radius: 4px;
}

.nav__dropdown--link {
    position: relative;
}

.nav__dropdown--link:hover .nav__dropdown {
    display: flex;
}

.nav__dropdown {
    position: absolute;
    display: none;
    background-color: #111;
    top: 120%;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    flex-direction: column;
    justify-content: center;
    z-index: 50;
    height: 180px;
    left: 16px;
}


.nav__dropdown li a {
    float: none;
    color: var(--primary);
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    font-size: 16px;
    text-align: left;
}

.logo {
    height: 52px;
    max-width: 74px;
}

.link__hover-effect {
    position: relative;
}

.link__hover-effect:after {
    content: " ";
    position: absolute;
    bottom: -3px;
    right: 0;
    width: 0%;
    background-color: var(--primary);
    transition: all 300ms ease;
}

.link__hover-effect-blue:after {
    background-color: #1F51FF;
}

.link__hover-effect:hover::after {
    left: 0;
    width: 100%;
    height: 9%;
}

.dropdown--link:hover::after {
    width: 80%;
}

.link__blue {
    color: #1F51FF !important;
}

/* 

Menu Backdrop

*/


.btn__menu {
    background-color: transparent;
    font-size: 40px;
    border: none;
    color: #44D62C;
    display: none;
}
.btn-home {
    position: absolute;
    top: 10px;
    right: 20px;
    padding: 8px;
}
.menu__backdrop {
    position: absolute;
    background-color: #111;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 120;
    display: flex;
    align-items: center;
    justify-content: center;
    visibility: hidden !important;
    transition: all 300ms ease;
    opacity: 0;
    transform: translate(100%);
}

.menu--open {
    max-height: 100vh;
    overflow: hidden;
}

.menu--open .menu__backdrop {
    visibility: visible !important;
    opacity: 1;
    transform: translate(0);
}

.menu__links {
    display: flex;
    flex-direction: column;
    width: 70%;
    align-items: center;
}

.menu__list {
    padding: 32px 0;
}

.menu__link {
    color: #44D62C;
    font-size: 26px;
}

.btn__menu--close {
    position: absolute;
    top: 10px;
    right: 20px;
    padding: 8px;
    color: #44D62C;
}

.menu__dropdown {
    flex-direction: column;
    margin-top: 10px;
}

.menu__dropdown--link {
    display: flex;
    align-items: flex-start;
    position: relative;

}

.menu__dropdown--icon {
    color: #1F51FF;
    font-size: 30px;
    position: absolute;
    right: -25px;
    top: -8px;
}

.dropdown--link--menu {
    margin: 4px 0;
}

@media (max-width: 960px) {
    .btn__menu {
        display: block;
    }

    .nav__links {
        display: none;
    }

    .highlight {
        width: 100%;
    }

    .highlight:not(:last-child) {
        margin-bottom: 24px;
    }
}

@media(min-width: 961px) {
    .menu--open .menu__backdrop {
        visibility: hidden !important;
    }
}