.ranks__card {
    background-color: #1c1b25;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 48%;
    gap: 8px;
    list-style: circle;
}

.rank__points li,
.rank__points {
    color: white;
    display: flex;
    flex-direction: column;
}

.main__title {
    font-size: 24px;
    border-image: linear-gradient(120deg, var(--primary), #1e90ff);
    border-image-slice: 1;
    border-style: solid;
    border-width: 5px 0 0;
    border-bottom: 5px solid;
    border-top: 0;
    margin: 0 auto;
    padding-bottom: 10px;
    width: 50%;
    text-align: center;
}

.ranks__card--title {
    color: var(--primary);
    background: linear-gradient(120deg,
            var(--primary), #a6c316);

    height: fit-content;
    font-weight: 700;
    background-clip: text;
    color: transparent;
}

.ranks__card:hover {
    transform: translateY(-5px) scale(1.005) translateZ(0);
    box-shadow: 0 18px 16px var(--primary), 0 18px 16px #1E90FF;
    transition: 0.5s;
}

.ranks__card::after,
::before {
    box-sizing: content-box !important;
}

.card__circle {
    margin-right: 4px;
}



@media(max-width:960px) {
    .ranks__card {
        width: 90%;
        justify-content: center;
    }

}